<!-- =========================================================================================
    File Name: ChatBots.vue
    Description: ChatBots Page
========================================================================================== -->

<template>
  <div>
    <!-- Popup WhatsApp API Smarters -->
    <vs-popup title="WhatsApp API" :active.sync="popupWhatsappApi">
      <div
        v-if="
          currentInboxChatData === undefined ||
          !currentInboxChatData.wppApiStatus ||
          currentInboxChatData.wppApiStatus === 0
        "
      >
        <h3>
          Para vincular o WhatsApp API ao Duotalk Chatbot informe os dados da
          integracao:
        </h3>

        <div class="mt-3">
          <div class="w-full mt-3">
            <vs-input
              type="password"
              label="API Access Token"
              v-model="whatsappApiAccessToken"
              class="w-full"
            />
          </div>
          <div class="w-full mt-3">
            <vs-input
              :label="$t('WhatsappNumber')"
              v-model="whatsappNumber"
              class="w-full"
            />
            <p class="text-gray-600 text-xs italic">
              Informe somente números inicando com o código país (Brasil: 55)
            </p>
          </div>
          <p class="text-danger italic mt-3" v-if="whatsappAPiSetupError">
            Error:
            {{ whatsappAPiSetupError }}
          </p>
          <div class="w-full mt-3">
            <vs-button @click="saveInboxChatConfig('wppApi')">
              {{ $t("Save") }}
            </vs-button>
          </div>
        </div>
      </div>

      <div v-else>
        <h3>WhatsApp Conectado ao Duotalk Chatbot!</h3>
        <div class="mt-3">
          Seu WhatsApp já está vinculado ao Duotalk Chatbot edite as informações
          do seu WhatsApp aqui.
        </div>
        <div class="font-bold">
          <p>Numero: {{ currentInboxChatData.wppApiWID }}</p>
        </div>
        <div class="mt-3">
          <form class="w-full max-w-lg">
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label for="" class="vs-input--label">{{
                  $t("Profile")
                }}</label>
                <div class="flex flex-wrap items-center">
                  <div
                    class="con-vs-avatar 49px"
                    style="
                      width: 49px;
                      height: 49px;
                      background: rgb(195, 195, 195);
                    "
                    id="avatarFileOnboarding"
                  >
                    <div class="con-img vs-avatar--con-img">
                      <img :src="chatAvatar" />
                    </div>
                  </div>
                  <div>
                    <feather-icon
                      v-if="chatAvatarFile.type"
                      @click="cancelAvatar"
                      icon="XIcon"
                      class="p-2"
                      svgClasses="w-6 h-6 text-danger"
                      style="cursor: pointer"
                    />
                    <feather-icon
                      v-if="chatAvatarFile.type"
                      @click="saveWhatsappApiAvatar"
                      icon="CheckIcon"
                      class="p-2"
                      svgClasses="w-6 h-6 text-success"
                      style="cursor: pointer"
                    />
                    <avatar-cropper
                      :uploadHandler="uploadAvatar"
                      @changed="changeFile"
                      trigger="#avatarFileOnboarding"
                      mimes="image/png, image/jpeg"
                      :labels="avatarCropperLabels"
                    />
                  </div>
                </div>
                <p class="text-gray-600 text-xs italic">
                  Recomendado imagem 650px x 650px de até 5mb.
                </p>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label for="" class="vs-input--label">Vertical</label>
                <v-select
                  v-model="inboxConfigDataWppApiVertical"
                  :clearable="false"
                  :options="wppApiVerticalsOptions"
                  name="wppdepartment"
                  class="w-full"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                />
                <p class="text-gray-600 text-xs italic mt-1">
                  {{ $t("TellCompanyIndustry") }}
                </p>
                <span class="text-danger text-sm">{{
                  errors.first("wppdepartment")
                }}</span>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label for="" class="vs-input--label">{{ $t("Email") }}</label>
                <vx-input-group>
                  <vs-input
                    :maxlength="maxLength128"
                    type="text"
                    class="w-full rounded-r-none h-auto"
                    v-model="inboxConfigDataWppApi.email"
                    name="wppemail"
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                  />
                  <template slot="append">
                    <div class="append-text bg-grey">
                      <span
                        v-text="
                          maxLength128 - inboxConfigDataWppApi.email
                            ? inboxConfigDataWppApi.email.length
                            : 0
                        "
                      ></span>
                    </div>
                  </template>
                </vx-input-group>
                <span class="text-danger text-sm">{{
                  errors.first("wppemail")
                }}</span>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label for="" class="vs-input--label">{{
                  $t("Website")
                }}</label>
                <vx-input-group>
                  <vs-input
                    :maxlength="maxLength256"
                    type="text"
                    class="w-full rounded-r-none h-auto"
                    v-model="inboxConfigDataWppApi.website"
                    name="wppwebsite"
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                  />
                  <template slot="append">
                    <div class="append-text bg-grey">
                      <span
                        v-text="
                          maxLength256 - inboxConfigDataWppApi.website
                            ? inboxConfigDataWppApi.website.length
                            : 0
                        "
                      ></span>
                    </div>
                  </template>
                </vx-input-group>
                <span class="text-danger text-sm">{{
                  errors.first("wppwebsite")
                }}</span>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label for="" class="vs-input--label">{{
                  $t("Address")
                }}</label>
                <vx-input-group>
                  <vs-input
                    :maxlength="maxLength256"
                    type="text"
                    class="w-full rounded-r-none h-auto"
                    v-model="inboxConfigDataWppApi.address"
                    name="wppaddress"
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                  />
                  <template slot="append">
                    <div class="append-text bg-grey">
                      <span
                        v-text="
                          maxLength256 - inboxConfigDataWppApi.address
                            ? inboxConfigDataWppApi.address.length
                            : 0
                        "
                      ></span>
                    </div>
                  </template>
                </vx-input-group>
                <span class="text-danger text-sm">{{
                  errors.first("wppaddress")
                }}</span>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label for="" class="vs-input--label">{{
                  $t("Description")
                }}</label>
                <vx-input-group>
                  <vs-textarea
                    class="w-full rounded-r-none h-auto"
                    v-model="inboxConfigDataWppApi.description"
                    :maxlength="maxLength256"
                    name="wppdescription"
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                  />
                  <template slot="append">
                    <div class="append-text bg-grey">
                      <span
                        v-text="
                          maxLength256 - inboxConfigDataWppApi.description
                            ? inboxConfigDataWppApi.description.length
                            : 0
                        "
                      ></span>
                    </div>
                  </template>
                </vx-input-group>
                <span class="text-danger text-sm">{{
                  errors.first("wppdescription")
                }}</span>
              </div>
            </div>
            <p class="text-danger italic mt-3" v-if="whatsappAPiSetupError">
              Error:
              {{ whatsappAPiSetupError }}
            </p>
            <vs-button
              color="success"
              @click="setWhatsappApiInfo"
              :disabled="
                !veeValidateForm ||
                !inboxConfigDataWppApi.address ||
                (inboxConfigDataWppApi.address &&
                  inboxConfigDataWppApi.address.length === 0)
              "
            >
              Salvar
            </vs-button>
          </form>
        </div>
        <div class="mt-6">
          Deseja efetuar logout?
          <a href="#_" @click="logoutInboxChatConfig('wppApi')"
            >Efetuar Logout</a
          >.
        </div>
      </div>
    </vs-popup>

    <!-- Popup WhatsApp API 360 -->
    <vs-popup title="WhatsApp API 360" :active.sync="popupWhatsappApi360">
      <div
        v-if="
          currentInboxChatData === undefined ||
          !currentInboxChatData.wppApi360DialogStatus ||
          currentInboxChatData.wppApi360DialogStatus === 0
        "
      >
        <h3 class="mb-5">Integrar WhatsApp Api a Duotalk com 360 Dialog:</h3>

        <ol class="mx-5" style="list-style-type: decimal">
          <li class="mb-1">
            Selecione se você quer migrar um número existente ou adicionar um
            novo para sua conta business.
          </li>
          <li>Cadastre uma conta de WhatsApp Api Business.</li>
        </ol>
        <div class="text-left mt-3">
          <label for="wppApi360PartnerSelect">PartnerID</label>
          <v-select
            id="wppApi360Select"
            :options="dialog360PartnerOptions"
            :clearable="false"
            v-model="dialog360PartnerSelected"
            class="w-full"
          />
        </div>
        <div class="mt-5 flex text-left" v-if="!whatsapp360DialogNumber">
          <vs-input
            type="text"
            name="phone"
            :placeholder="$t('Phone')"
            v-model="dialog360Prenumber"
            class="w-full"
            @keyup.enter="signUp360Dialog"
            :disabled="signUp360DialogOnGoing"
          />
          <vs-button
            :disabled="signUp360DialogOnGoing"
            @click="signUp360Dialog"
            icon-pack="feather"
            icon="icon-arrow-right"
            class="ml-3"
          />
        </div>
        <div class="mt-5 text-center" v-else>
          <ul>
            <li class="text-left">
              <label for="dialog360channel"
                >360Dialog Waba Channel (Não preencha se não souber pra que
                serve)</label
              >
              <vs-input
                type="text"
                name="dialog360channel"
                v-model="dialog360channel"
                class="w-full"
              />
            </li>
            <li class="text-left mt-3">
              <label for="wppApi360Select">WhatsApp channel</label>
              <v-select
                id="wppApi360Select"
                :options="dialog360APITypeOptions"
                :clearable="false"
                v-model="dialog360APITypeSelected"
                class="w-full"
              />
            </li>
          </ul>
          <div
            class="inline-block mt-5"
            @click="openSignInWindow(currentInboxChatData.id)"
          >
            <div
              style="width: 200px; height: 30px; z-index: 99999"
              class="cursor-pointer absolute"
            ></div>
            <div href="" class="whatsapp-api-button connect">
              <vs-avatar
                :src="
                  require(`@/assets/images/portrait/icons/whatsapp business.png`)
                "
                style="background: none !important"
                size="30px"
              ></vs-avatar>
              <p style="margin-right: 5px">Entrar com o WhatsApp</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h3>WhatsApp Conectado ao Duotalk Chatbot! <span>360 Dialog</span></h3>
        <div class="mt-3">
          Seu WhatsApp já está vinculado ao Duotalk Chatbot edite as informações
          do seu WhatsApp aqui.
        </div>
        <div class="font-bold">
          <p>
            {{ $t("WhatsappNumber") }}:
            {{ currentInboxChatData.wppApi360DialogWID }}
          </p>
        </div>
        <div class="mt-3">
          <form class="w-full max-w-lg">
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label for="" class="vs-input--label">{{
                  $t("Profile")
                }}</label>
                <div class="flex flex-wrap items-center">
                  <div
                    class="con-vs-avatar 49px"
                    style="
                      width: 49px;
                      height: 49px;
                      background: rgb(195, 195, 195);
                    "
                    id="avatarFileOnboarding"
                  >
                    <div class="con-img vs-avatar--con-img">
                      <img :src="chatAvatar" />
                    </div>
                  </div>
                  <div>
                    <feather-icon
                      v-if="chatAvatarFile.type"
                      @click="cancelAvatar"
                      icon="XIcon"
                      class="p-2"
                      svgClasses="w-6 h-6 text-danger"
                      style="cursor: pointer"
                    />
                    <feather-icon
                      v-if="chatAvatarFile.type"
                      @click="saveWhatsappApi360DialogAvatar"
                      icon="CheckIcon"
                      class="p-2"
                      svgClasses="w-6 h-6 text-success"
                      style="cursor: pointer"
                    />
                    <avatar-cropper
                      :uploadHandler="uploadAvatar"
                      @changed="changeFile"
                      trigger="#avatarFileOnboarding"
                      mimes="image/png, image/jpeg"
                      :labels="avatarCropperLabels"
                    />
                  </div>
                </div>
                <p class="text-gray-600 text-xs italic">
                  Recomendado imagem 650px x 650px de até 5mb.
                </p>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label for="" class="vs-input--label">Vertical</label>
                <v-select
                  v-model="inboxConfigDataWppApiVertical"
                  :clearable="false"
                  :options="wppApiVerticalsOptions"
                  name="wppdepartment"
                  class="w-full"
                  data-vv-validate-on="blur"
                />
                <p class="text-gray-600 text-xs italic mt-1">
                  {{ $t("TellCompanyIndustry") }}
                </p>
                <span class="text-danger text-sm">{{
                  errors.first("wppdepartment")
                }}</span>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label for="" class="vs-input--label">{{ $t("Email") }}</label>
                <vx-input-group>
                  <vs-input
                    :maxlength="maxLength128"
                    type="text"
                    class="w-full rounded-r-none h-auto"
                    v-model="inboxConfigDataWppApi.email"
                    name="wppemail"
                    data-vv-validate-on="blur"
                  />
                  <template slot="append">
                    <div class="append-text bg-grey">
                      <span
                        v-text="
                          maxLength128 - inboxConfigDataWppApi.email.length
                        "
                      ></span>
                    </div>
                  </template>
                </vx-input-group>
                <span class="text-danger text-sm">{{
                  errors.first("wppemail")
                }}</span>
              </div>
              <div class="w-full md:w-1/2 px-3">
                <label for="" class="vs-input--label">{{
                  $t("Website")
                }}</label>
                <vx-input-group>
                  <vs-input
                    :maxlength="maxLength256"
                    type="text"
                    class="w-full rounded-r-none h-auto"
                    v-model="inboxConfigDataWppApi.website"
                    name="wppwebsite"
                    data-vv-validate-on="blur"
                  />
                  <template slot="append">
                    <div class="append-text bg-grey">
                      <span
                        v-text="
                          maxLength256 - inboxConfigDataWppApi.website.length
                        "
                      ></span>
                    </div>
                  </template>
                </vx-input-group>
                <span class="text-danger text-sm">{{
                  errors.first("wppwebsite")
                }}</span>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label for="" class="vs-input--label">{{
                  $t("Address")
                }}</label>
                <vx-input-group>
                  <vs-input
                    :maxlength="maxLength256"
                    type="text"
                    class="w-full rounded-r-none h-auto"
                    v-model="inboxConfigDataWppApi.address"
                    name="wppaddress"
                    data-vv-validate-on="blur"
                  />
                  <template slot="append">
                    <div class="append-text bg-grey">
                      <span
                        v-text="
                          maxLength256 - inboxConfigDataWppApi.address.length
                        "
                      ></span>
                    </div>
                  </template>
                </vx-input-group>
                <span class="text-danger text-sm">{{
                  errors.first("wppaddress")
                }}</span>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-6">
              <div class="w-full px-3">
                <label for="" class="vs-input--label">{{
                  $t("Description")
                }}</label>
                <vx-input-group>
                  <vs-textarea
                    class="w-full rounded-r-none h-auto"
                    v-model="inboxConfigDataWppApi.description"
                    :maxlength="maxLength256"
                    name="wppdescription"
                    data-vv-validate-on="blur"
                  />
                  <template slot="append">
                    <div class="append-text bg-grey">
                      <span
                        v-text="
                          maxLength256 -
                          inboxConfigDataWppApi.description.length
                        "
                      ></span>
                    </div>
                  </template>
                </vx-input-group>
                <span class="text-danger text-sm">{{
                  errors.first("wppdescription")
                }}</span>
              </div>
            </div>
            <p class="text-danger italic mt-3" v-if="whatsappAPiSetupError">
              Error:
              {{ whatsappAPiSetupError }}
            </p>
            <ul class="my-5">
              <li>
                <label for="wppApi360Select"
                  >WhatsApp channel for Webhook</label
                >
                <v-select
                  id="wppApi360Select"
                  :options="dialog360APITypeOptions"
                  :clearable="false"
                  v-model="dialog360APITypeSelected"
                  class="w-full"
                />
              </li>
              <li class="mt-5">
                <label for="wppApi360PartnerSelect">PartnerID</label>
                <v-select
                  id="wppApi360Select"
                  :options="dialog360PartnerOptions"
                  :clearable="false"
                  v-model="dialog360PartnerSelected"
                  class="w-full"
                />
              </li>
            </ul>
            <vs-button color="success" @click="setWhatsappApi360DialogInfo">
              Salvar
            </vs-button>
            <vs-button class="ml-3" @click="whatsapp360ReloadWebhook">
              Reload Webhook
            </vs-button>
          </form>
        </div>
        <div class="mt-6">
          Deseja efetuar logout?
          <a href="#_" @click="logoutInboxChatConfig('wppApi360Dialog')"
            >Efetuar Logout</a
          >.
        </div>
      </div>
    </vs-popup>

    <div v-if="subscriptionPurchased">
      <div class="vx-col w-full mb-base">
        <vs-alert
          :active.sync="subscriptionPurchased"
          closable
          close-icon="cancel"
          color="success"
        >
          <div class="text-center">
            {{ $t("SubscriptionSuccessfull", [this.planName]) }}
          </div>
        </vs-alert>
      </div>
    </div>
    <div>
      <h3 class="mb-5 mt-10">
        {{ $t("CreatedChatbots") }}
      </h3>
    </div>

    <!-- Chatbots Criados -->
    <div class="vx-row">
      <div
        class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base"
        v-for="item in chatbotData"
        :key="item.id"
        :id="'chatcard_' + item.id"
      >
        <vs-card>
          <div slot="header" class="flex justify-between items-center">
            <div class="flex items-center">
              <vs-chip
                :color="item.isAssistant ? 'warning' : 'primary'"
                class="mr-3 mb-0 border-solid"
                :class="[
                  item.isAssistant ? 'border-warning' : 'border-primary',
                ]"
                transparent
                v-if="$store.state.acc.current_acc.assistantEnable"
              >
                {{ item.isAssistant ? "Bot IA" : "Bot Fluxo" }}
              </vs-chip>
              <h4 class="whitespace-nowrap">
                {{ item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name }}
              </h4>
            </div>
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <vs-button
                icon-pack="feather"
                icon="icon-more-vertical"
                type="flat"
                radius
              />
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <vs-button
                    @click="editChatbot(item.id, item.isAssistant)"
                    icon-pack="feather"
                    icon="icon-edit"
                    type="flat"
                  >
                    {{ $t("Edit") }}
                  </vs-button>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <vs-button
                    @click="openConfirm(item.id)"
                    icon-pack="feather"
                    icon="icon-trash"
                    type="flat"
                    color="danger"
                  >
                    {{ $t("Delete") }}
                  </vs-button>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <vs-divider color="primary">{{ $t("Channels") }}</vs-divider>
          <div class="flex flex-wrap md:flex-nowrap justify-center">
            <div class="w-auto">
              <vs-button
                color="primary"
                :type="item.webChatStatus === 1 ? 'filled' : 'border'"
                v-on:click="setCurrentChat(item)"
                @click="popupSite = true"
                class="m-0 xl:mr-2 mb-3 w-full"
              >
                <div class="flex justify-center flex-wrap">
                  <p class="w-full mt-2 flex items-center text-center justify-center">
                    <feather-icon icon="LayoutIcon" svgClasses="w-4 h-4 mr-2" />
                    Site
                  </p>
                  <vs-chip
                    :color="item.webChatStatus === 1 ? 'success' : 'danger'"
                    :transparent="item.webChatStatus!==1"
                    class="mt-2"
                  >
                      <strong v-if="item.webChatStatus === 1">{{ $t("Active") }}</strong>
                      <span v-else>{{ $t("Pending") }}</span>
                  </vs-chip>
                </div>
              </vs-button>

              <!-- Whatsapp button -->
              <vs-button
                v-if="planData['inboxAvailable']"
                color="primary"
                :type="
                  integrationButtonType(
                    item.wppApiStatus === 1 ||
                      item.wppApi360DialogStatus === 1 ||
                      item.wppStatus
                  )
                "
                class="mb-3 m-0 md:mr-2 w-full"
                v-on:click="changeCurrentChat(item.id)"
                @click="popupWhatsappOptions = true"
              >
                <div class="flex justify-center flex-wrap">
                  <p class="w-full mt-2 flex items-center text-center justify-center">
                    <WhatsappIcon :color="item.wppApiStatus === 1 || item.wppApi360DialogStatus === 1 || item.wppStatus ? 'white' : '#7367f0'" width="14px" height="14px" class="mr-2" />
                    WhatsApp
                  </p>
                  <vs-chip
                    color="success"
                    class="mt-2"
                    v-if="
                      item.wppApiStatus === 1 ||
                      item.wppApi360DialogStatus === 1 ||
                      item.wppStatus
                    "
                    >{{ $t("Active") }}</vs-chip
                  >
                  <vs-chip transparent color="primary" class="mt-2" v-else>{{
                    $t("Options")
                  }}</vs-chip>
                </div>
              </vs-button>

              <!-- Facebook button -->
              <vs-button
                v-if="!item.facebookStatus && planData['inboxAvailable']"
                color="primary"
                :type="integrationButtonType(item.facebookStatus)"
                class="mb-3 m-0 md:mr-2 w-full"
                v-on:click="changeCurrentFacebookChat(item.id, item.facebookStatus)"
                @click="popupFacebook = true"
              >
                <div class="flex justify-center flex-wrap">
                  <p class="w-full mt-2 flex items-center text-center justify-center">
                    <vs-icon icon-pack="feather" icon="icon-facebook" color="primary" class="mr-2" size="16px" />
                    Facebook
                  </p>
                  <vs-chip transparent color="danger" class="mt-2">{{
                    $t("Pending")
                  }}</vs-chip>
                </div>
              </vs-button>
              <vs-button
                v-if="item.facebookStatus === 1 || item.facebookStatus === 3"
                color="primary"
                :type="integrationButtonType(item.facebookStatus)"
                class="mb-3 m-0 md:mr-2 w-full"
                v-on:click="changeCurrentFacebookChat(item.id, item.facebookStatus)"
                @click="popupFacebook = true"
              >
                <div class="flex justify-center flex-wrap">
                  <p class="w-full mt-2 flex items-center text-center justify-center">
                    <vs-icon icon-pack="feather" icon="icon-facebook" color="white" class="mr-2" size="16px" />
                    Facebook
                  </p>
                  <vs-chip color="success" class="mt-2">{{
                    $t("Active")
                  }}</vs-chip>
                </div>
              </vs-button>
              <!-- Instagram button -->
              <vs-button
                v-if="!item.instagramStatus && planData['inboxAvailable']"
                color="primary"
                type="border"
                class="mb-3 m-0 md:mr-2 w-full"
                v-on:click="
                  changeCurrentInstagramChat(item.id, item.instagramStatus)
                "
                @click="popupInstagram = true"
              >
                <div class="flex justify-center flex-wrap">
                  <p class="w-full mt-2 flex items-center text-center justify-center">
                    <vs-icon icon-pack="feather" icon="icon-instagram" color="primary" class="mr-2" />
                    Instagram
                  </p>
                  <vs-chip transparent color="danger" class="mt-2">{{
                    $t("Pending")
                  }}</vs-chip>
                </div>
              </vs-button>
              <vs-button
                v-if="item.instagramStatus === 1 || item.instagramStatus === 3"
                color="primary"
                :type="integrationButtonType(item.instagramStatus)"
                class="mb-3 m-0 md:mr-2 w-full"
                v-on:click="
                  changeCurrentInstagramChat(item.id, item.instagramStatus)
                "
                @click="popupInstagram = true"
              >
                <div class="flex justify-center flex-wrap">
                  <p class="w-full mt-2 flex items-center text-center justify-center">
                    <vs-icon icon-pack="feather" icon="icon-instagram" color="white" class="mr-2" />
                    Instagram
                  </p>
                  <vs-chip color="success" class="mt-2">{{
                    $t("Active")
                  }}</vs-chip>
                </div>
              </vs-button>
            </div>
          </div>

          <!-- Popup Install Instructions -->
          <vs-popup
            class="holamundo"
            :title="$t('WebsiteInstalation')"
            :active.sync="popupSite"
            id="popup-chatbot"
          >
            <vs-collapse v-if="currentChat.isAssistant">
              <vs-collapse-item>
                <div slot="header">Instalar no site com JavaScript</div>
                <p class="mb-3">
                  Copie o código e cole antes do fechamento da tag
                  <code>&#60;/body&#62;</code> do seu site.
                </p>
                <vx-input-group class="copy-code-flex">
                  <pre class="rounded-l-lg language-mark m-0 p-0">
                    <code style="background: transparent;" @click="CopyToClipboard('language-markup-ai',currentChatId)">
      &lt;!-- duotalk AI --&gt;
      &lt;script src="https://cdn.duotalk.com.br/widget/ai/duotalk.snippet.js"&gt;&lt;/script&gt;
      &lt;script type='text/javascript'&gt;
        loadDuotalkWidget({
            chatID: "{{ currentChatId }}",
            accID: "{{ accID }}"
        });
      &lt;/script&gt;</code>
                  </pre>
                  <template slot="append">
                    <div
                      class="append-text bg-success p-0"
                      @click="
                        CopyToClipboard('language-markup-ai', currentChatId)
                      "
                    >
                      <vx-tooltip :text="$t('Copy')">
                        <vs-button
                          color="success"
                          class
                          type="filled"
                          icon="icon-copy"
                          icon-pack="feather"
                        />
                      </vx-tooltip>
                    </div>
                  </template>
                </vx-input-group>
              </vs-collapse-item>
              <vs-collapse-item>
                <div slot="header">{{ $t("OptionalParameters") }}</div>
                <ul>
                  <li>Name: John Doe</li>
                  <li>Email: john.doe@duotalk.io</li>
                  <li>Phone: +55 (11) 1999-9999</li>
                </ul>
                <div class="mt-2">
                  {{ $t("AIUsageExample") }}:
                  <pre
                    class="rounded-lg language-mark m-0 p-0"
                    style="cursor: auto"
                  >
                  <code style="background: transparent;" >
      &lt;!-- duotalk AI --&gt;
      &lt;script src="https://cdn.duotalk.com.br/widget/ai/duotalk.snippet.js"&gt;&lt;/script&gt;
      &lt;script type='text/javascript'&gt;
        loadDuotalkWidget({
            chatID: "{{ currentChatId }}",
            accID: "{{ accID }}",
            name: "Nome do Usuário",
            email: "usuario@dominio.com",
            phone: "+55 (11) 1999-9999"
        });
      &lt;/script&gt;</code>
                  </pre>
                </div>
              </vs-collapse-item>
            </vs-collapse>
            <vs-collapse v-else>
              <vs-collapse-item :open="open">
                <div slot="header">{{ $t("ChatbotHintInstWJavaScript") }}</div>
                <p class="mb-3">
                  {{ $t("ChatbotHintCopyTheCode") }}
                  <code>&#60;/body&#62;</code>
                  {{ $t("ChatbotHintOfYourSite") }}.
                </p>
                <vx-input-group class="copy-code-flex">
                  <pre class="rounded-l-lg language-mark m-0 p-0">
                    <code style="background: transparent;" @click="CopyToClipboard('language-markup',currentChatId)">
                    &lt;!-- duotalk --&gt;
                    &lt;script type='text/javascript'&gt;
                      window.__cb = window.__cb || {};
                      window.__cb.id = "{{ currentChatId }}"; (function() { var
                      be = document.createElement('script'); be.type =
                      'text/javascript'; be.async = true; be.src = ('https:' ==
                      document.location.protocol ? 'https://' : 'http://') +
                      'cdn.duotalk.com.br/widget/plugin.js'; var s =
                      document.getElementsByTagName('script')[0];
                      s.parentNode.insertBefore(be, s); })();
                    &lt;/script&gt;</code>
                  </pre>
                  <template slot="append">
                    <div
                      class="append-text bg-success p-0"
                      @click="CopyToClipboard('language-markup', currentChatId)"
                    >
                      <vx-tooltip :text="$t('Copy')">
                        <vs-button
                          @click="
                            CopyToClipboard('language-markup', currentChatId)
                          "
                          color="success"
                          class
                          type="filled"
                          icon="icon-copy"
                          icon-pack="feather"
                        />
                      </vx-tooltip>
                    </div>
                  </template>
                </vx-input-group>
              </vs-collapse-item>
              <!-- wordpress -->
              <vs-collapse-item>
                <div slot="header">
                  {{ $t("ChatbotHintInstallOnWordpress") }}
                </div>
                <p class="mb-4">1. {{ $t("InstallScriptWordpress.1") }}</p>
                <p class="mb-4">2. {{ $t("InstallScriptWordpress.2") }}</p>
                <p class="mb-4">3. {{ $t("InstallScriptWordpress.3") }}</p>
                <p class="mb-4">4. {{ $t("InstallScriptWordpress.4") }}</p>
                <vx-input-group class="copy-code-flex mb-4">
                  <pre class="rounded-l-lg language-mark m-0 p-0">
                    <code style="background: transparent;" @click="CopyToClipboard('language-markup',currentChatId)">
                    &lt;!-- duotalk - --&gt;
                    &lt;script type='text/javascript'&gt;
                      window.__cb = window.__cb || {};
                      window.__cb.id = "{{ currentChatId }}"; (function() { var
                      be = document.createElement('script'); be.type =
                      'text/javascript'; be.async = true; be.src = ('https:' ==
                      document.location.protocol ? 'https://' : 'http://') +
                      'cdn.duotalk.com.br/widget/plugin.js'; var s =
                      document.getElementsByTagName('script')[0];
                      s.parentNode.insertBefore(be, s); })();
                    &lt;/script&gt;</code>
                  </pre>
                  <template slot="append">
                    <div
                      class="append-text bg-success p-0"
                      @click="CopyToClipboard('language-markup', currentChatId)"
                    >
                      <vx-tooltip :text="$t('Copy')">
                        <vs-button
                          @click="
                            CopyToClipboard('language-markup', currentChatId)
                          "
                          color="success"
                          class
                          type="filled"
                          icon="icon-copy"
                          icon-pack="feather"
                        />
                      </vx-tooltip>
                    </div>
                  </template>
                </vx-input-group>
                <p class="mb-4">5. {{ $t("InstallScriptWordpress.5") }}</p>
              </vs-collapse-item>
              <vs-collapse-item>
                <div slot="header">
                  {{ $t("ChatbotHintSendToTheDeveloper") }}
                </div>
                <p>{{ $t("ChatbotHintIfYouPrefere") }}.</p>
                <div class="centerx default-input mt-2 mb-4">
                  <p>
                    <vs-input
                      v-validate="'required|email'"
                      data-vv-validate-on="blur"
                      class="h-12 inputx mb-4"
                      placeholder="Insira um e-mail"
                      v-model="emailCodeDev"
                      name="email"
                    />
                    <small class="text-danger">{{
                      errors.first("email")
                    }}</small>
                  </p>
                  <vs-button
                    @click="sendEmailNotification(currentChatId)"
                    color="primary"
                    type="filled"
                    >{{ $t("SendCode") }}</vs-button
                  >
                </div>
              </vs-collapse-item>
              <vs-collapse-item>
                <div slot="header">{{ $t("OtherPlatforms") }}</div>
                <p>{{ $t("ChatbotHintOtherPlatforms") }}.</p>
                <p class="mt-2">{{ $t("CheckOurAppStore") }}.</p>
                <vs-button
                  class="w-full mt-6"
                  type="border"
                  icon="icon-arrow-right"
                  icon-pack="feather"
                  @click="$router.push('appstore')"
                >
                  {{ $t("GoToAppStore") }}
                </vs-button>
              </vs-collapse-item>
            </vs-collapse>

            <div class="w-full flex justify-center">
              <vs-button
                color="success"
                :label="$t('Done')"
                class
                type="filled"
                icon="icon-check"
                icon-pack="feather"
                @click="(popupSite = false), getChatbots()"
              >
                {{ $t("Done") }}
              </vs-button>
            </div>
          </vs-popup>

          <!-- Popup WhatsApp Options -->
          <vs-popup
            :title="$t('WhatsappOptions')"
            style="z-index: 500"
            :active.sync="popupWhatsappOptions"
          >
            <!-- WhatsApp API Smarters button -->
            <vs-button
              v-if="planData['inboxAvailable']"
              color="primary"
              :type="integrationButtonType(currentInboxChatData.wppApiStatus)"
              class="mb-3 m-0 md:mr-2 w-full"
              v-on:click="
                changeCurrentChat(
                  currentInboxChatData.id,
                  currentInboxChatData.wppApiStatus
                )
              "
              @click="popupWhatsappApi = true"
            >
              <div class="flex justify-center flex-wrap">
                <p class="w-full mt-2">
                  <img
                    src="@/assets/images/icon-whatsapp.svg"
                    class="text-primary inline"
                    alt="whatsApp"
                    height="14"
                    title="whatsApp"
                  />
                  WhatsApp API
                </p>
                <vs-chip
                  transparent
                  color="danger"
                  class="mt-2"
                  v-if="
                    !currentInboxChatData.wppApiStatus ||
                    currentInboxChatData.wppApiStatus === 0
                  "
                  >{{ $t("Pending") }}</vs-chip
                >
                <vs-chip
                  color="success"
                  class="mt-2"
                  v-if="currentInboxChatData.wppApiStatus == 1"
                  >{{ $t("Active") }}</vs-chip
                >
                <vs-chip
                  color="warning"
                  class="mt-2"
                  v-if="currentInboxChatData.wppApiStatus == 3"
                  >{{ $t("Reconnecting") }}</vs-chip
                >
              </div>
            </vs-button>
            <!-- WhatsApp API 360 Dialog button -->
            <vs-button
              v-if="planData['inboxAvailable']"
              color="primary"
              :type="
                integrationButtonType(
                  currentInboxChatData.wppApi360DialogStatus
                )
              "
              class="mb-3 m-0 md:mr-2 w-full"
              v-on:click="
                changeCurrentChat(
                  currentInboxChatData.id,
                  currentInboxChatData.wppApi360DialogStatus
                )
              "
              @click="openPopupWhatsappApi360(currentInboxChatData.id)"
            >
              <div class="flex justify-center flex-wrap">
                <p class="w-full mt-2">
                  <img
                    src="@/assets/images/icon-whatsapp.svg"
                    class="text-primary inline"
                    alt="whatsApp"
                    height="14"
                    title="whatsApp"
                  />
                  WhatsApp API 360 Dialog
                </p>
                <vs-chip
                  transparent
                  color="danger"
                  class="mt-2"
                  v-if="
                    !currentInboxChatData.wppApi360DialogStatus ||
                    currentInboxChatData.wppApi360DialogStatus === 0
                  "
                  >{{ $t("Pending") }}</vs-chip
                >
                <vs-chip
                  color="success"
                  class="mt-2"
                  v-if="currentInboxChatData.wppApi360DialogStatus == 1"
                  >{{ $t("Active") }}</vs-chip
                >
                <vs-chip
                  color="warning"
                  class="mt-2"
                  v-if="currentInboxChatData.wppApi360DialogStatus == 3"
                  >{{ $t("Reconnecting") }}</vs-chip
                >
              </div>
            </vs-button>
          </vs-popup>

          <!-- Popup Facebook -->
          <vs-popup title="Conectar ao Facebook" :active.sync="popupFacebook">
            <div
              v-if="
                displayButtonLogWithFacebook !== 'success' &&
                displayButtonLogWithFacebook !== 'loading'
              "
              style="padding-left: 40px"
            >
              <h3>Para ativar o Facebook ao Duotalk Chatbot:</h3>
              <br />
              <ul>
                <li>1. Faça o Login no Facebook.</li>
                <li>
                  2. Escolha a Página do Facebook que deseja conectar o chatbot.
                </li>
                <li>3. Avance e conclua todas as etapas.</li>
              </ul>
              <br />
              <div class="text-center">
                <facebook-login-button
                  @login="loginFacebookOrInstagram"
                  integration="facebook"
                  @pages="getPages"
                />
              </div>
              <br />
            </div>
            <div v-else-if="displayButtonLogWithFacebook === 'success'">
              <div style="padding-left: 40px">
                <h3>Você está conectado ao Facebook!</h3>
                <br />
                <div v-if="fbPages.length > 0">
                  <span class="text-bold">
                    Encontramos {{ fbPages.length }} Páginas do Facebook que
                    você administra.
                  </span>
                  <div class="mt-5" :key="page.id" v-for="page in fbPages">
                    <div class="flex justify-between">
                      <span class="font-bold">
                        {{ page.name }}
                      </span>

                      {{ $t("Connected") }}
                    </div>
                    <vs-divider />
                  </div>
                </div>
                <li>
                  Sua página do facebook já está vinculado ao Duotalk Chatbot e
                  pronto para ser utilizado! Para acessar suas conversas
                  <a href="/apps/inbox">clique aqui</a>.
                </li>
              </div>
              <div class="text-center">
                <br />
                <span
                  class="material-icons-outlined text-primary"
                  style="font-size: 120pt"
                >
                  forum
                </span>
                <br />
                <vs-button @click="logoutFacebookConfirmation('facebook')">
                  Logout
                </vs-button>
                <br />
                <br />
              </div>
            </div>
          </vs-popup>

          <!-- Popup Instagram -->
          <vs-popup title="Conectar ao Instagram" :active.sync="popupInstagram">
            <div
              v-if="
                displayButtonLogWithInstagram !== 'success' &&
                displayButtonLogWithInstagram !== 'loading'
              "
              style="padding-left: 40px"
            >
              <h3>Para ativar o Instagram ao Duotalk Chatbot:</h3>
              <br />
              <p>
                Antes de ativar, verifique se sua conta do Instagram está
                vinculada a tua Página do Facebook,
                <a
                  href="https://www.facebook.com/business/help/898752960195806"
                  target="_blank"
                  >veja como vincular</a
                >.
              </p>
              <br />
              <ul>
                <li>
                  1. Faça o Login no Facebook integrado com sua conta do
                  Instagram.
                </li>
                <li>2. Escolha a página referente a sua conta do Instagram.</li>
                <li>3. Em seguida selecione o perfil do instagram.</li>
                <li>4. Avance e conclua todas as etapas.</li>
              </ul>
              <br />
              <div class="text-center">
                <facebook-login-button
                  @login="loginFacebookOrInstagram"
                  integration="instagram"
                />
              </div>
              <br />
            </div>
            <div v-else>
              <div style="padding-left: 40px">
                <h3>Você está conectado ao Instagram!</h3>
                <br />
                <li>
                  Sua página do Instagram já está vinculado ao Duotalk Chatbot e
                  pronto para ser utilizada! Para acessar suas conversas
                  <a href="/apps/inbox">clique aqui</a>.
                </li>
              </div>
              <div class="text-center">
                <br />
                <span
                  class="material-icons-outlined text-primary"
                  style="font-size: 120pt"
                >
                  forum
                </span>
                <br />
                <vs-button @click="logoutFacebookConfirmation('instagram')">
                  Logout
                </vs-button>
                <br />
                <br />
              </div>
            </div>
          </vs-popup>

          <div class="hidden" id="language-markup">
            &lt;!-- duotalk - --&gt; &lt;script type='text/javascript'&gt;
            window.__cb = window.__cb || {}; window.__cb.id = "__id__";
            (function() { var be = document.createElement('script'); be.type =
            'text/javascript'; be.async = true; be.src = ('https:' ==
            document.location.protocol ? 'https://' : 'http://') +
            'cdn.duotalk.com.br/widget/plugin.js'; var s =
            document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(be, s); })(); &lt;/script&gt;
          </div>
          <div class="hidden" id="language-markup-ai">
            &lt;!-- duotalk AI --&gt; &lt;script
            src="https://cdn.duotalk.com.br/widget/ai/duotalk.snippet.js"&gt;&lt;/script&gt;
            &lt;script type='text/javascript'&gt; loadDuotalkWidget({ chatID:
            "__id__", accID: "{{ accID }}" });
          </div>
        </vs-card>
      </div>

      <div
        class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base"
        v-if="$acl.check('master')"
      >
        <vx-card
          class="flex items-center justify-center cursor-pointer h-full bg-grey-light"
          @click="createNewChatbot()"
        >
          <div class="flex items-center justify-center">
            <feather-icon
              icon="PlusIcon"
              svgClasses="w-8 h-8"
              color="primary"
            ></feather-icon>
          </div>

          <div class="flex items-center justify-center mt-3">
            <h5>{{ $t("CreateNewChatbot") }}</h5>
          </div>
        </vx-card>
      </div>
    </div>

    <!-- Popup de escolha do tipo de criação de chatbot -->
    <vs-popup
      title="Selecionar o tipo de chatbot"
      :active.sync="popupTypeChatbot"
    >
      <vs-row>
        <vs-col vs-w="12">
          <div
            role="button"
            class="flex items-center border-solid border hover:border-primary transition group rounded-md gap-x-5 p-4 cursor-pointer"
            :class="[typeChatbot === 1 ? 'border-primary' : 'border-grey']"
            @click="typeChatbot = 1"
          >
            <feather-icon
              icon="ZapIcon"
              svgClasses="w-8 h-8 group-hover:text-primary transition"
              :class="[typeChatbot === 1 ? 'text-primary' : 'text-grey']"
            />
            <p
              class="text-2xl leading-none group-hover:text-primary transition"
              :class="[typeChatbot === 1 ? 'text-primary' : 'text-grey']"
            >
              Inteligência Artificial<br />
              <span class="text-sm"
                >Importe arquivos e treine seu chatbot para entender e responder
                seu contatos</span
              >
            </p>
          </div>
        </vs-col>
        <vs-col vs-w="12" class="mt-5">
          <div
            role="button"
            class="flex items-center border-solid border hover:border-primary transition group rounded-md gap-x-5 p-4 cursor-pointer"
            :class="[typeChatbot === 2 ? 'border-primary' : 'border-grey']"
            @click="typeChatbot = 2"
          >
            <feather-icon
              icon="GitMergeIcon"
              svgClasses="w-8 h-8 group-hover:text-primary transition"
              :class="[typeChatbot === 2 ? 'text-primary' : 'text-grey']"
            />
            <p
              class="text-2xl leading-none group-hover:text-primary transition"
              :class="[typeChatbot === 2 ? 'text-primary' : 'text-grey']"
            >
              Fluxo Padrão<br />
              <span class="text-sm"
                >Crie um fluxo pré-definido de perguntas e respostas a ser
                seguido pelo bot</span
              >
            </p>
          </div>
        </vs-col>
        <vs-col vs-w="12" class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            color="primary"
            icon-pack="feather"
            icon="icon-arrow-right"
            icon-after
            :disabled="typeChatbot === null"
            @click="goTo(typeChatbot)"
          >
            Continuar
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>

    <div v-if="false" class="vx-row">
      <div
        class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base templateList"
        v-for="item in templatesData"
        :key="item.id"
        :taglist="item.tagsClass"
      >
        <vx-card :title="item.name">
          <div class="chatbot-status">
            <img
              :src="item.img"
              alt="content-img"
              class="responsive rounded-lg"
            />
            <vs-chip color="dark" v-if="item.chatType == 2">PRO</vs-chip>
          </div>
          <div class="my-6">
            <h5>{{ item.title }}</h5>
          </div>

          <div class="flex flex-wrap">
            <template>
              <vs-chip transparent v-for="(tag, j) in item.tags" :key="j">{{
                tag
              }}</vs-chip>
            </template>
          </div>
          <vs-divider></vs-divider>
          <div class="justify-between flex-wrap">
            <vs-button
              :to="{
                name: 'chatbot-create',
                params: { template_id: item.id, chatbotType: 'chatbot' },
              }"
              class="mt-4 mr-2 w-full"
              color="primary"
              >{{ $t("CreateChatbot") }}</vs-button
            >
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "../../../axios";
import { accMixin } from "@/mixins/mixins.js";
import moduleChatbotManagement from "@/store/chatbot-management/moduleChatbotManagement.js";
import AvatarCropper from "vue-avatar-cropper";
import WhatsappIcon from "@/components/svg/WhatsappIcon.vue";
import FacebookLoginButton from "@/components/duotalk-fb-chatbot-login-button/FacebookLoginButton.vue";

export default {
  mixins: [accMixin],
  data() {
    return {
      currentChat: {},
      fbPages: [],
      popupTypeChatbot: false,
      typeChatbot: null,
      signUp360DialogOnGoing: false,
      dialog360Prenumber: "",
      dialog360channel: "",
      dialog360APITypeOptions: ["CLOUD API", "ON-PREMISE"],
      dialog360APITypeSelected: "CLOUD API",
      dialog360PartnerOptions: [
        "Duotalk - Partner Payment (Tyon7vPA)",
        "Duotalk (ByX4OePA)",
      ],
      dialog360PartnerSelected: "Duotalk - Partner Payment (Tyon7vPA)",
      accepterms: false,
      avatarCropperLabels: {
        submit: this.$t("Crop"),
        cancel: this.$t("Cancel"),
      },
      clickNotClose: true,
      chatAvatarFile: "",
      chatAvatar:
        "https://cdn.duotalk.com.br/img/uploads/whatsapp-default-picture.png",
      chatAvatarOld: "",
      DefaultAvatar:
        "https://cdn.duotalk.com.br/img/uploads/whatsapp-default-picture.png",
      currentChatId: "",
      currentChatInboxChatConfig: null,
      currentFacebookChatId: "",
      currentQrChatID: "",
      displayButtonLogWithFacebook: "pending",
      displayButtonLogWithInstagram: "pending",
      maxLength256: 256,
      maxLength128: 128,
      emailCodeDev: "",
      inboxConfigDataWppApi: {
        email: "",
        website: "",
        address: "",
        description: "",
        vertical: "",
      },
      inboxConfigDataWppApiVertical: { id: "", label: "" },
      tag: "Todos",
      id: "",
      //popupActive: false,
      activeConfirm: false,
      chatbotData: {},
      templatesData: [],
      noChatbot: false,
      tagsItems: [],
      deleteChat: null,
      isActive: false,
      isFilterSidebarActive: true,
      open: true,
      popupCodeDev: false,
      popupSite: false,
      popupWhatsappApi: false,
      popupWhatsappApi360: false,
      popupWhatsappOptions: false,
      popupFacebook: false,
      popupInstagram: false,
      popupChatpage: false,
      qrcode: "",
      tagInput: "",
      windowWidth: window.innerWidth,
      whatsappApiStatus: false,
      whatsappApiAccessToken: null,
      whatsappAPiSetupError: null,
      whatsappNumber: null,
      whatsappApi360DialogStatus: false,
      whatsappApi360DialogAccessToken: null,
      whatsappAPi360DialogSetupError: null,
      whatsapp360DialogNumber: null,
      tooltipText: this.$t("Copy"),
    };
  },
  components: {
    WhatsappIcon,
    vSelect,
    AvatarCropper,
    FacebookLoginButton,
  },
  computed: {
    accID() {
      return this.$store.state.acc.current_acc.id;
    },
    webhook() {
      return `${process.env.VUE_APP_API_URL}p/chat/sms/webhook`;
    },
    veeValidateForm() {
      return !this.errors.any();
    },
    planData: function () {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters;
    },
    currentInboxChatData: function () {
      if (!this.currentChatInboxChatConfig) return {};
      let elementIndex = this.chatbotData.findIndex(
        (el) => el.id === this.currentChatInboxChatConfig
      );
      if (elementIndex > -1) return this.chatbotData[elementIndex];
      else return {};
    },
    wppApiVerticalsOptions: function () {
      return [
        { id: "", label: "" },
        { id: "AUTO", label: this.$t("WppApiVerticalAutomotive") },
        {
          id: "Beauty, Spa and Salon",
          label: this.$t("WppApiVerticalBeautySpaAndSalon"),
        },
        {
          id: "Clothing and Apparel",
          label: this.$t("WppApiVerticalClothingAndApparel"),
        },
        { id: "Education", label: this.$t("WppApiVerticalEducation") },
        {
          id: "Entertainment",
          label: this.$t("WppApiVerticalEntertainment"),
        },
        {
          id: "Event Planning and Service",
          label: this.$t("WppApiVerticalEventPlanningAndService"),
        },
        {
          id: "Finance and Banking",
          label: this.$t("WppApiVerticalFinAnceandBanking"),
        },
        {
          id: "Food and Grocery",
          label: this.$t("WppApiVerticalFoodAndGrocery"),
        },
        {
          id: "Public Service",
          label: this.$t("WppApiVerticalPublicService"),
        },
        {
          id: "Hotel and Lodging",
          label: this.$t("WppApiVerticalHotelAndLodging"),
        },
        {
          id: "Medical and Health",
          label: this.$t("WppApiVerticalMedicalAndHealth"),
        },
        { id: "Non-profit", label: this.$t("WppApiVerticalNonprofit") },
        {
          id: "Professional Services",
          label: this.$t("WppApiVerticalProfessionalServices"),
        },
        {
          id: "Shopping and Retail",
          label: this.$t("WppApiVerticalShoppingAndRetail"),
        },
        {
          id: "Travel and Transportation",
          label: this.$t("WppApiVerticalTravelAndTransportation"),
        },
        { id: "Restaurant", label: this.$t("WppApiVerticalRestaurant") },
        { id: "Other", label: this.$t("WppApiVerticalOther") },
      ];
    },
  },
  watch: {
    popupWhatsappApi: function (val) {
      if (val) this.getWhatsappApiInfo();
    },
    popupWhatsappApi360: function (val) {
      if (val) this.getWhatsappApi360DialogInfo();
    },
  },
  methods: {
    async copyUrl() {
      try {
        await navigator.clipboard.writeText(this.webhook);
        this.tooltipText = this.$t("Copied");
        setTimeout(() => {
          this.tooltipText = this.$t("Copy");
        }, 6000);
      } catch ($e) {
        this.tooltipText = "Cannot copy";
      }
    },
    openPopupWhatsappApi360(chatID) {
      this.currentChatInboxChatConfig = chatID;

      this.popupWhatsappApi360 = true;
    },
    async signUp360Dialog() {
      try {
        this.signUp360DialogOnGoing = true;
        const partnerID =
          this.dialog360PartnerSelected ===
          "Duotalk - Partner Payment (Tyon7vPA)"
            ? "Tyon7vPA"
            : "ByX4OePA";
        this.dialog360Prenumber = this.dialog360Prenumber.replace(/\D/g, "");

        const res = await axios({
          method: "get",
          url: "/p/chat/360Dialog/signup",
          params: {
            partnerID,
            id: this.dialog360channel,
            phone: this.dialog360Prenumber,
          },
        });
        this.signUp360DialogOnGoing = false;
        this.dialog360channel = "";
        if (res.data.data.phoneNumber) {
          this.whatsappApi360DialogAccessToken = res.data.data.apiKey.api_key;
          this.whatsapp360DialogNumber = res.data.data.phoneNumber;

          this.$vs.loading.close();

          await this.saveInboxChatConfig("wppApi360");
          this.dialog360Prenumber = "";
        } else {
          if (this.whatsapp360DialogNumber)
            this.$vs.dialog({
              type: "alert",
              color: "danger",
              title: "Error",
              text: this.$t("Dialog360SignupErrorAPIGen"),
              acceptText: this.$t("Close"),
            });
          this.whatsapp360DialogNumber = this.dialog360Prenumber;
        }
      } catch (e) {
        this.$vs.loading.close();
        this.signUp360DialogOnGoing = false;
        this.$vs.dialog({
          type: "alert",
          color: "danger",
          title: "Error on Signup",
          text: e.response.data.message,
          acceptText: this.$t("Close"),
        });
      }
    },
    async openSignInWindow() {
      const partnerID =
        this.dialog360PartnerSelected === "Duotalk - Partner Payment (Tyon7vPA)"
          ? "Tyon7vPA"
          : "ByX4OePA";
      if (this.dialog360channel.trim() !== "") {
        this.signUp360Dialog();

        return;
      }

      let h = 900;
      let w = 1000;
      let left = screen.width / 2 - w / 2;
      let top = screen.height / 2 - h / 2;

      let url = `https://hub.360dialog.com/dashboard/app/${partnerID}/permissions?language=pt`;

      if (this.dialog360APITypeSelected === "ON-PREMISE") {
        url = url.concat("&hosting_type=onpremise");
      }

      if (
        process.env.NODE_ENV === "development" ||
        window.location.origin.includes("app2")
      ) {
        url = url.concat(
          `&redirect_url=${window.location.origin}${window.location.pathname}`
        );
      }

      const baseUrl = window.location.origin;
      const name = "connect-360dialog";

      let windowObjectReference = null;
      let previousUrl = null;

      const receiveMessage = async (event, baseUrl) => {
        if (event.origin != baseUrl) {
          return;
        }
        // remove any existing event listeners
        window.removeEventListener("message", messageEventListener, true);

        const { data } = event;

        if (data.client) {
          const channels = data.channels
            .replace("[", "")
            .replace("]", "")
            .split(",");
          const id =
            process.env.NODE_ENV === "development"
              ? channels[0] || "JlsIxdCH"
              : channels[0];
          this.$vs.loading();

          try {
            const res = await axios({
              method: "get",
              url: `/p/chat/360Dialog/event/${id}`,
              data: { ...data, partnerID },
              params: { partnerID },
            });

            if (res.data.data.phoneNumber) {
              this.whatsappApi360DialogAccessToken =
                res.data.data.apiKey.api_key;
              this.whatsapp360DialogNumber = res.data.data.phoneNumber;

              this.$vs.loading.close();

              this.saveInboxChatConfig("wppApi360");
            } else {
              this.$vs.dialog({
                type: "alert",
                color: "primary",
                title: `Número não encontrado.`,
                text: `Nenhum número encontrado por favor tente novamente.`,
              });
            }
          } catch (e) {
            this.$vs.loading.close();

            this.$vs.dialog({
              type: "alert",
              color: "danger",
              title: `Erro ao Gerar Api Key`,
              text: e.response.data.message,
            });
          }
          // remove any existing event listeners
          window.removeEventListener("message", receiveMessage);
        }
      };

      const messageEventListener = (event) => receiveMessage(event, baseUrl);

      // remove any existing event listeners
      window.removeEventListener("message", messageEventListener, true);

      // window features
      const strWindowFeatures =
        "toolbar=no, menubar=no" +
        `width=${w},height=${h},left=${left},top=${top}`;

      if (windowObjectReference === null || windowObjectReference.closed) {
        /* if the pointer to the window object in memory does not exist
      or if such pointer exists but the window was closed */
        windowObjectReference = window.open(url, name, strWindowFeatures);
      } else if (previousUrl !== url) {
        /* if the resource to load is different,
      then we load it in the already opened secondary window and then
      we bring such window back on top/in front of its parent window. */
        windowObjectReference = window.open(url, name, strWindowFeatures);
        windowObjectReference.focus();
      } else {
        /* else the window reference must exist and the window
      is not closed; therefore, we can bring it back on top of any other
      window with the focus() method. There would be no need to re-create
      the window or to reload the referenced resource. */
        windowObjectReference.focus();
      }

      windowObjectReference.addEventListener(
        "load",
        function (e) {
          e.preventDefault();
        },
        false
      );

      // add the listener for receiving a message from the popup
      window.addEventListener("message", messageEventListener, true);
      // assign the previous URL
      previousUrl = url;

      /* The callback function returns the client ID as well as all channel IDs, for which you're enabled to fetch the API key via the Partner API */
      // console.log("client ID: " + callback.client);
      // console.log("channel IDs: " + callback.channels);
    },
    getUrlParameter(params, name) {
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      var results = regex.exec(params);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    resizeBase64Img(base64, callback) {
      let img = document.createElement("img");
      img.src = base64;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;
        const MAX_WIDTH = 640;
        const MAX_HEIGHT = Math.ceil((height / width) * MAX_WIDTH);

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        callback(canvas.toDataURL());
      };
    },
    removeAvatar() {
      this.chatAvatar = this.DefaultAvatar;
    },
    cancelAvatar() {
      this.chatAvatar = this.chatAvatarOld;
      this.chatAvatarFile = "";
    },
    uploadAvatar: function (photo) {
      if (this.chatAvatarOld === "") this.chatAvatarOld = this.chatAvatar;
      var self = this;
      if (photo) {
        var url = photo.getCroppedCanvas().toDataURL();
        this.resizeBase64Img(url, function (url) {
          fetch(url).then((resBlob) => {
            resBlob.blob().then((blob) => {
              self.chatAvatarFile = blob;
              self.chatAvatar = url;
            });
          });
        });
      }
    },
    changeFile(file) {
      if (file)
        document.querySelector(".avatar-cropper").style.display = "flex";
    },
    logoutFacebookConfirmation(channel) {
      const self = this;
      const channelU = `${channel[0].toUpperCase()}${channel.slice(
        1,
        channel.length
      )}`;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Encerrar Conversa",
        text: "Uma vez que a integração Facebook e Instagram são partilhadas, o logout afetará as duas integrações. Deseja continuar?",
        accept: () => self.logoutFacebook(channel),
        acceptText: "Logout",
        cancel: () => (self[`popup${channelU}`] = false),
        cancelText: this.$t("Cancel"),
      });
    },
    async loginFacebookOrInstagram(authResponse, integration) {
      if (!authResponse) return;
      const chatID =
        integration === "facebook"
          ? this.currentFacebookChatId
          : this.currentInstagramChatId;
      axios({
        url: `/p/chat/facebook/auth?chatID=${chatID}&channel=${integration}`,
        method: "POST",
        data: {
          authResponse: authResponse,
          acc: "" + this.$store.state.acc.current_acc.id + "",
        },
      })
        .then(async (response) => {
          if (response.status === 200) {
            if (integration === "facebook") {
              this.loggedInFacebook = true;
              this.displayButtonLogWithFacebook = "success";
            } else {
              this.loggedInInstagram = true;
              this.displayButtonLogWithInstagram = "success";
            }

            this.chatbotData = this.chatbotData.map((botData) => {
              if (botData.id === chatID) {
                return {
                  ...botData,
                  [integration === "facebook"
                    ? "facebookStatus"
                    : "instagramStatus"]: 1,
                };
              }
              return botData;
            });

            this.$vs.notify({
              title: this.$t("Success"),
              text:
                integration === "facebook"
                  ? this.$t("LoginWithFacebook")
                  : this.$t("LoginWithInstagram"),
              iconPack: "feather",
              icon: "icon-success-circle",
              color: "success",
              position: "top-right",
              time: 4000,
            });
          }
        })
        .catch(async () => {
          this.displayButtonLogWithFacebook = "pending";
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: this.$t("PageLoginWithFacebookPermissionsFail"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
    async logoutFacebook(channel) {
      const channelU = `${channel[0].toUpperCase()}${channel.slice(
        1,
        channel.length
      )}`;

      this[`displayButtonLogWith${channelU}`] = "loading";
      axios({
        url: `/p/chat/facebook/logout?chat=${this[`current${channelU}ChatId`]}`,
        method: "PUT",
      }).then(async (response) => {
        if (response.status === 200) {
          this[`loggedIn${channelU}`] = false;
          this[`displayButtonLogWith${channelU}`] = "pending";
          this[`popup${channelU}`] = false;
          const currentChat = this[`current${channelU}ChatId`];
          this.chatbotData = this.chatbotData.map((botData) => {
            if (botData.id === currentChat) {
              return { ...botData, facebookStatus: 0, instagramStatus: 0 };
            }
            return botData;
          });

          this.$vs.notify({
            title: this.$t("Success"),
            text: this.$t("LogoutSuccess"),
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        }
      });
    },

    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 992) {
        this.isFilterSidebarActive = this.clickNotClose = false;
      } else {
        this.isFilterSidebarActive = this.clickNotClose = true;
      }
    },
    fetchChatbot(id) {
      if (!moduleChatbotManagement.isRegistered) {
        this.$store.registerModule(
          "chatbotManagement",
          moduleChatbotManagement
        );
        moduleChatbotManagement.isRegistered = true;
      }
      this.$store.dispatch("chatbotManagement/fetchChatbot", id).then(() => {
        this.$router.push({
          name: "chatbot-flow-edit",
          params: { editFlow: 1 },
        });
      });
    },
    sendEmailNotification(id) {
      if (this.errors.any()) return;
      this.$http({
        method: "POST",
        url: `/g/notification/chat/email/${id}?acc=${this.$store.state.acc.current_acc.id}`,
        data: {
          email: this.emailCodeDev,
        },
      })
        .then(() => {
          this.emailCodeDev = "";
          this.popupActive = false;
          this.$vs.notify({
            title: this.$t("Success"),
            text: this.$t("EmailSent"),
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          if (error.request != null)
            if (typeof error.request.response !== "undefined")
              error.message =
                JSON.parse(error.request.response).data != null
                  ? Array.isArray(JSON.parse(error.request.response).data)
                    ? JSON.parse(error.request.response).data[0].msg
                    : ""
                  : JSON.parse(error.request.response).message;
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    copyTextSuccess() {
      this.$vs.notify({
        title: this.$t("Success"),
        text: this.$t("CopiedToClipboard"),
        color: "success",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-check-circle",
        time: 4000,
      });
    },
    copyTextError() {
      this.$vs.notify({
        title: this.$t("Error"),
        text: this.$t("UnexpectedError"),
        color: "danger",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-alert-circle",
        time: 4000,
      });
    },
    seeChatpage(url) {
      window.open(url, "_blank");
    },
    getChatbots() {
      this.$vs.loading();

      this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          let ArrayChats = [];
          for (let i = 0; i < response.data.data.length; i++) {
            let inboxConfig = {};
            if (response.data.data[i].inboxConfig)
              inboxConfig = response.data.data[i].inboxConfig;
            ArrayChats.push({
              ...inboxConfig,
              ...response.data.data[i],
              id: response.data.data[i]._id,
              name: response.data.data[i].name,
              status: response.data.data[i].status,
              website: response.data.data[i].website,
              wppStatus: inboxConfig.wppStatus || 0,
              wppApiStatus: inboxConfig.wppApiStatus || 0,
              wppApiWID: inboxConfig.wppApiWID,
              wppApi360DialogStatus: inboxConfig.wppApi360DialogStatus || 0,
              wppApi360DialogWID: inboxConfig.wppApi360DialogWID,
              facebookStatus: inboxConfig.facebookStatus || 0,
              instagramStatus: inboxConfig.instagramStatus || 0,
              webChatStatus: inboxConfig.webChatStatus || 0,
              wppApi360DialogCloudApi: inboxConfig.wppApi360DialogCloudApi || 0,
              templateName: response.data.data[i].templateData
                ? response.data.data[i].templateData.name
                : "",
              templateDescription: response.data.data[i].templateData
                ? response.data.data[i].templateData.description
                : "",
              img: response.data.data[i].templateData
                ? response.data.data[i].templateData.img
                : "",
              chatpage: null,
              isAssistant: response.data.data[i].isAssistant,
            });
          }
          this.chatbotData = ArrayChats;

          if (response.data.data.length === 0) this.noChatbot = true;

          this.getTemplates();
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getTemplates() {
      this.$vs.loading();
      this.$http
        .get("/p/chat/template/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((response) => {
          for (var i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].listingAvailable === true)
              this.templatesData.push({
                title: response.data.data[i].name,
                id: response.data.data[i]._id,
                img: response.data.data[i].img,
                chatType: response.data.data[i].chatType,
                lang: response.data.data[i].lang,
                description: response.data.data[i].description,
                version: response.data.data[i].version,
                urlChat: response.data.data[i].urlChat,
                tags: response.data.data[i].tags,
                tagsClass: response.data.data[i].tags.join(" "),
                tagsList: response.data.data[i].tags
                  .join(" / ")
                  .replace("-", " "),
              });
          }
          this.$vs.loading.close();
        });
    },
    openConfirm(id) {
      this.deleteChat = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("ConfirmDeleteOfX", ["Chatbot"]),
        text: this.$t("ConfirmDeletedPermanentlyX", [this.$t("TheChatbot")]),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    acceptAlert() {
      this.$http
        .delete("/p/chat/" + this.deleteChat, {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.$store
              .dispatch("acc/setCurrentAccount", {
                acc: this.$store.state.acc.current_acc.id,
              })
              .then(() => {
                this.$acl.change(this.$store.state.user.userRole);
              });
            document.getElementById("chatcard_" + this.deleteChat).remove();
            this.$vs.notify({
              color: "success",
              title: "Chatbot " + this.$t("Deleted"),
              text: this.$t("TheChatbot") + " " + this.$t("DeletedSuccessfuly"),
              position: "top-right",
              time: 4000,
            });

            this.getChatbots();
          }
        })
        .catch(() => {
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: this.$t("UnexpectedErrorDeleteX", ["chatbot"]),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            duration: 10000,
            position: "top-right",
            time: 4000,
          });
        });
    },
    clicked(val) {
      this.$emit("input", val);
    },
    createChatbot() {
      this.$router.push("/create/chatbot").catch(() => {});
    },
    makeData() {
      var self = this;
      for (var i = 0; i < self.resultNames.length; i++) {
        self.copyCode += "<p>" + self.resultNames[i] + "</p>";
      }
    },
    copyData() {
      this.makeData();
    },
    CopyToClipboard(containerid, id) {
      var str = document.getElementById(containerid).innerHTML;
      str = str.replace("__id__", id);

      const el = document.createElement("textarea");

      var eStr = document.createElement("div");
      eStr.innerHTML = str;
      str = eStr.childNodes[0].nodeValue;

      el.value = str;

      document.body.appendChild(el);
      el.select();
      el.setSelectionRange(0, 99999); /*For mobile devices*/

      document.execCommand("copy");
      document.body.removeChild(el);

      this.$vs.notify({
        title: this.$t("Success"),
        text: this.$t("CopiedToClipboard"),
        iconPack: "feather",
        icon: "icon-success-circle",
        color: "success",
        position: "top-right",
        time: 4000,
      });
    },
    changeCurrentChat(chat_id, wppStatus) {
      this.currentChatInboxChatConfig = chat_id;
      if (this.currentQrChatID !== chat_id) {
        this.currentQrChatID = chat_id;
        if (wppStatus) {
          this.displayQr = "success";
        } else {
          this.displayQr = "pending";
        }
      }
    },
    changeCurrentFacebookChat(chat_id, facebookStatus) {
      if (this.currentFacebookChatId !== chat_id) {
        this.currentFacebookChatId = chat_id;

        if (facebookStatus) {
          this.displayButtonLogWithFacebook = "success";
        } else {
          this.displayButtonLogWithFacebook = "pending";
        }
      }
    },
    changeCurrentInstagramChat(chat_id, instagramStatus) {
      if (this.currentInstagramChatId !== chat_id) {
        this.currentInstagramChatId = chat_id;

        if (instagramStatus) {
          this.displayButtonLogWithInstagram = "success";
        } else {
          this.displayButtonLogWithInstagram = "pending";
        }
      }
    },
    saveInboxChatConfig(integration) {
      let data;
      if (integration === "wppApi360") {
        data = {
          wppApi360DialogCloudApi:
            this.dialog360APITypeSelected === "CLOUD API",
          wppApi360DialogStatus: 1,
          wppApi360DialogAccessToken:
            this.whatsappApi360DialogAccessToken.trim(),
          wpp360DialogNumber: this.whatsapp360DialogNumber.trim(),
          wpp360PartnerID:
            this.dialog360PartnerSelected ===
            "Duotalk - Partner Payment (Tyon7vPA)"
              ? "Tyon7vPA"
              : "ByX4OePA",
          acc: this.$store.state.acc.current_acc.id,
        };
      }
      else if (integration === "wppApi") {
        if (
          !this.whatsappApiAccessToken ||
          this.whatsappApiAccessToken.length < 3
        ) {
          this.whatsappAPiSetupError = "Informe o access token";
          return;
        } else this.whatsappAPiSetupError = "";
        if (!this.whatsappNumber || this.whatsappNumber.length < 11) {
          this.whatsappAPiSetupError =
            "Informe somente números iniciando com o código país (Brasil: 55) e DDD";
          return;
        } else this.whatsappAPiSetupError = "";

        data = {
          wppApiStatus: 1,
          wppApiAccessToken: this.whatsappApiAccessToken.trim(),
          wppNumber: this.whatsappNumber.trim(),
          acc: this.$store.state.acc.current_acc.id,
        };
      }
      this.$vs.loading();
      axios({
        method: "post",
        url: `/p/chat/inbox/chat/${this.currentChatInboxChatConfig}`,
        data,
      })
        .then(async (response) => {
          this.$vs.loading.close();
          const index = this.chatbotData.findIndex(
            (e) => e.id === this.currentChatInboxChatConfig
          );

          this.currentChatInboxChatConfig = this.chatbotData[index].id;
          if (index > -1)
            this.chatbotData[index] = Object.assign(
              this.chatbotData[index],
              response.data.data
            );

          if (integration === "wppApi") await this.getWhatsappApiInfo();
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    logoutInboxChatConfig(integration) {
      this.$vs.loading();
      let url;
      let integrationStatusKey;
      let method = "post";
      let data;
      if (integration === "wppApi") {
        integrationStatusKey = "wppApiStatus";
        url = `/p/chat/inbox/chat/${this.currentChatInboxChatConfig}`;
        data = {
          acc: this.$store.state.acc.current_acc.id,
          wppApiStatus: 0,
          wppApiAccessToken: "",
          wppNumber: "",
        };
      }
      else if (integration === "wppApi360Dialog") {
        integrationStatusKey = "wppApi360DialogStatus";
        url = `/p/chat/inbox/chat/${this.currentChatInboxChatConfig}`;
        data = {
          acc: this.$store.state.acc.current_acc.id,
          wppApi360DialogStatus: 0,
          wppApi360DialogAccessToken: "",
          wpp360DialogNumber: "",
          wppApi360DialogCloudApi: false,
        };
      }
      axios({
        method,
        url,
        data,
      })
        .then(() => {
          this.$vs.loading.close();
          const index = this.chatbotData.findIndex(
            (e) => e.id === this.currentChatInboxChatConfig
          );

          if (index > -1)
            this.chatbotData[index][`${integrationStatusKey}`] = 0;
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    resetWhatsappApiInfo() {
      this.inboxConfigDataWppApi = {
        address: "",
        description: "",
        email: "",
        website: "",
      };
      this.chatAvatarFile = "";
      this.chatAvatar = this.DefaultAvatar;
      this.inboxConfigDataWppApiVertical = { id: "", label: "" };
    },
    getWhatsappApi360DialogInfo() {
      this.whatsapp360DialogNumber = "";
      const index = this.chatbotData.findIndex(
        (el) => el.id === this.currentChatInboxChatConfig
      );

      if (index > -1 && this.chatbotData[index].wppApi360DialogStatus !== 1) {
        return;
      }

      if (this.chatbotData[index].wppApi360DialogCloudApi)
        this.dialog360APITypeSelected = "CLOUD API";
      else this.dialog360APITypeSelected = "ON-PREMISE";

      if (this.chatbotData[index].wppApi360DialogPartnerID === "ByX4OePA")
        this.dialog360PartnerSelected = "Duotalk (ByX4OePA)";
      else
        this.dialog360PartnerSelected = "Duotalk - Partner Payment (Tyon7vPA)";

      this.resetWhatsappApiInfo();
      this.$vs.loading();
      axios({
        method: "get",
        url: `/p/chat/inbox/provider/whatsapp-api/info/${this.currentChatInboxChatConfig}`,
        params: {
          acc: this.$store.state.acc.current_acc.id,
          channel: "WhatsApp 360",
        },
      })
        .then((response) => {
          this.$vs.loading.close();
          const data = response.data.data;
          this.chatAvatar =
            data.profilePic ||
            "https://cdn.duotalk.com.br/img/uploads/whatsapp-default-picture.png";
          this.inboxConfigDataWppApi = {
            address: data.address || "",
            description: data.description || "",
            email: data.email || "",
            website:
              data.websites && Array.isArray(data.websites)
                ? data.websites[0]
                : "",
          };
          const index = this.wppApiVerticalsOptions.findIndex(
            (el) => el.id === data.vertical
          );
          if (index > -1)
            this.inboxConfigDataWppApiVertical =
              this.wppApiVerticalsOptions[index];
          else {
            this.wppApiVerticalsOptions.push({
              id: data.vertical,
              label: data.vertical,
            });
            this.inboxConfigDataWppApiVertical = {
              id: data.vertical,
              label: data.vertical,
            };
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    saveWhatsappApi360DialogAvatar() {
      const index = this.chatbotData.findIndex(
        (el) => el.id === this.currentChatInboxChatConfig
      );
      if (index > -1 && this.chatbotData[index].wppApi360DialogStatus !== 1)
        return;
      this.$vs.loading();
      let fd = new FormData();
      fd.append("content", this.chatAvatar);
      fd.append("mime", this.chatAvatarFile.type);
      axios({
        method: "post",
        url: `/p/chat/inbox/provider/whatsapp-api/pic/${this.currentChatInboxChatConfig}`,
        params: {
          acc: this.$store.state.acc.current_acc.id,
          channel: "WhatsApp 360",
        },
        data: fd,
      })
        .then(() => {
          this.$vs.loading.close();
          this.chatAvatarFile = "";
          this.$vs.notify({
            title: this.$t("Success"),
            text: this.$t("SuccessWhatsappEditPic"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    getWhatsappApiInfo() {
      const index = this.chatbotData.findIndex(
        (el) => el.id === this.currentChatInboxChatConfig
      );
      if (index > -1 && this.chatbotData[index].wppApiStatus !== 1) return;
      this.resetWhatsappApiInfo();
      this.$vs.loading();
      axios({
        method: "get",
        url: `/p/chat/inbox/provider/whatsapp-api/info/${this.currentChatInboxChatConfig}`,
        params: { acc: this.$store.state.acc.current_acc.id },
      })
        .then((response) => {
          this.$vs.loading.close();
          const data = response.data.data;
          this.chatAvatar = data.profilePic;
          this.inboxConfigDataWppApi = {
            address: data.address || "",
            description: data.description || "",
            email: data.email || "",
            website:
              data.websites && Array.isArray(data.websites)
                ? data.websites[0]
                : "",
          };
          const index = this.wppApiVerticalsOptions.findIndex(
            (el) => el.id === data.vertical
          );
          if (index > -1)
            this.inboxConfigDataWppApiVertical =
              this.wppApiVerticalsOptions[index];
          else {
            this.wppApiVerticalsOptions.push({
              id: data.vertical,
              label: data.vertical,
            });
            this.inboxConfigDataWppApiVertical = {
              id: data.vertical,
              label: data.vertical,
            };
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    async whatsapp360ReloadWebhook() {
      const partnerID =
        this.dialog360PartnerSelected === "Duotalk - Partner Payment (Tyon7vPA)"
          ? "Tyon7vPA"
          : "ByX4OePA";
      const index = this.chatbotData.findIndex(
        (el) => el.id === this.currentChatInboxChatConfig
      );
      if (index > -1 && this.chatbotData[index].wppApi360DialogStatus !== 1)
        return;
      this.$vs.loading();

      try {
        await axios({
          method: "post",
          url: `/p/chat/360Dialog/reload/webhook/${this.currentChatInboxChatConfig}`,
          params: {
            acc: this.$store.state.acc.current_acc.id,
            partnerID,
          },
          data: {
            isCloudApi: this.dialog360APITypeSelected === "CLOUD API",
            partnerID,
          },
        });

        this.$vs.notify({
          title: this.$t("Success"),
          text: "Webhook reloaded successfully",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
          duration: 10000,
          position: "top-right",
          time: 4000,
        });
      } catch (e) {
        this.$vs.notify({
          title: `${this.$t("UnexpectedError")} - ${e.response.status}`,
          text: e.response.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 5000,
        });
      }

      this.$vs.loading.close();
    },
    setWhatsappApi360DialogInfo() {
      const index = this.chatbotData.findIndex(
        (el) => el.id === this.currentChatInboxChatConfig
      );
      if (index > -1 && this.chatbotData[index].wppApi360DialogStatus !== 1)
        return;
      this.$vs.loading();

      this.inboxConfigDataWppApi.vertical =
        this.inboxConfigDataWppApiVertical.id;
      if (this.chatAvatarFile !== "")
        this.saveWhatsappApi360DialogAvatarAvatar();
      axios({
        method: "put",
        url: `/p/chat/inbox/provider/whatsapp-api/info/${this.currentChatInboxChatConfig}`,
        params: {
          acc: this.$store.state.acc.current_acc.id,
          channel: "WhatsApp 360",
        },
        data: {
          ...this.inboxConfigDataWppApi,
          wpp360PartnerID:
            this.dialog360PartnerSelected ===
            "Duotalk - Partner Payment (Tyon7vPA)"
              ? "Tyon7vPA"
              : "ByX4OePA",
        },
      })
        .then(() => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: this.$t("Success"),
            text: this.$t("SuccessWhatsappEditInfo"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
            duration: 10000,
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    saveWhatsappApiAvatar() {
      const index = this.chatbotData.findIndex(
        (el) => el.id === this.currentChatInboxChatConfig
      );
      if (index > -1 && this.chatbotData[index].wppApiStatus !== 1) return;
      this.$vs.loading();
      let fd = new FormData();
      fd.append("content", this.chatAvatar);
      fd.append("mime", this.chatAvatarFile.type);
      axios({
        method: "post",
        url: `/p/chat/inbox/provider/whatsapp-api/pic/${this.currentChatInboxChatConfig}`,
        params: { acc: this.$store.state.acc.current_acc.id },
        data: fd,
      })
        .then(() => {
          this.$vs.loading.close();
          this.chatAvatarFile = "";
          this.$vs.notify({
            title: this.$t("Success"),
            text: this.$t("SuccessWhatsappEditPic"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    setWhatsappApiInfo() {
      const index = this.chatbotData.findIndex(
        (el) => el.id === this.currentChatInboxChatConfig
      );
      if (index > -1 && this.chatbotData[index].wppApiStatus !== 1) return;
      this.$vs.loading();
      if (!this.inboxConfigDataWppApiVertical.id)
        this.whatsappAPiSetupError = "Informe o access token";
      this.inboxConfigDataWppApi.vertical =
        this.inboxConfigDataWppApiVertical.id;
      if (this.chatAvatarFile !== "") this.saveWhatsappApiAvatar();
      axios({
        method: "put",
        url: `/p/chat/inbox/provider/whatsapp-api/info/${this.currentChatInboxChatConfig}`,
        params: { acc: this.$store.state.acc.current_acc.id },
        data: this.inboxConfigDataWppApi,
      })
        .then(() => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: this.$t("Success"),
            text: this.$t("SuccessWhatsappEditInfo"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
            duration: 10000,
            position: "top-right",
            time: 4000,
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorCode;
          let errorMessage;
          if (error.response) {
            // Request made and server responded
            errorMessage = error.response.data.message;
            errorCode = error.response.status;
          } else if (error.request) {
            errorMessage = this.$t("UnexpectedError");
          } else {
            errorMessage = error.message;
          }
          this.$vs.notify({
            title: `${this.$t("UnexpectedError")} - ${errorCode}`,
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 5000,
          });
        });
    },
    getPages(pages) {
      this.fbPages = pages;
    },
    integrationButtonType(val) {
      return !val || val === 0 ? "border" : "filled";
    },
    logout(chat) {
      axios({
        method: "put",
        url: `/p/chat/whatsapp/${chat}/logout`,
      })
        .then(() => {
          this.displayQr = "pending";
          this.chatbotData = this.chatbotData.map((botData) => {
            if (botData.id === chat) {
              return { ...botData, wppStatus: 0 };
            }
            return botData;
          });
        })
        .catch(() => {});
    },
    setCurrentChat(item) {
      this.currentChat = item;
      this.currentChatId = item.id;
    },
    editChatbot(id, isAssistant) {
      window.analytics.track(
        "Editar Chatbot",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      if (isAssistant)
        this.$router.push({
          name: "chatbot-ai",
          params: {
            chatbot_id: id,
          },
        });
      else this.$router.push("/apps/chatbot/edit/" + id);
    },
    createNewChatbot() {
      if (this.$store.state.acc.current_acc.assistantEnable)
        this.popupTypeChatbot = true;
      else
        this.$router.push({
          name: "chatbot-create",
          params: { chatbotType: "chatbot" },
        });
    },
    goTo(chatbotType) {
      if (chatbotType === 1) {
        this.$router.push({
          name: "chatbot-ai",
        });
      } else {
        this.$router.push({
          name: "chatbot-create",
          params: {
            chatbotType: "chatbot",
          },
        });
      }
    },
  },
  created() {
    const params = window.location.search;
    const client = this.getUrlParameter(params, "client");
    const channels = this.getUrlParameter(params, "channels");
    const revokedChannels = this.getUrlParameter(params, "revoked");

    if (client && channels) {
      const channelsArray = channels
        .substring(1, channels.length - 1)
        .split(",");

      let callbackObj = {
        client: client,
        channels: channelsArray,
      };

      if (revokedChannels) {
        callbackObj["revokedChannels"] = revokedChannels
          .substring(1, revokedChannels.length - 1)
          .split(",");
      }

      // remove search parameters from URL after fetching them
      // window.history.replaceState(null, "", window.location.pathname);
    }

    // send to parent (opener) window and close small window
    if (window.opener) {
      window.opener.postMessage({ client, channels });
      window.close();
    }

    this.getChatbots();
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
  },
};
</script>
<style lang="scss" scoped>
.vs-tooltip {
  z-index: 99999 !important;
}
#popup-chatbot .vs-collapse {
  padding: 0 !important;
}
#algolia-content-container {
  .vs-sidebar {
    position: relative;
    float: left;
  }
}
@media screen and (max-width: 640px) {
  .width-input-markup {
    .vx-input-group-default {
      max-width: 220px !important;
    }
  }
}
@media (min-width: 640px) {
  .width-input-markup {
    .vx-input-group-default {
      max-width: 500px !important;
    }
  }
}
.vs-dialog-danger {
  z-index: 99999 !important;
}
#demo-basic-card {
  .overlay-card {
    .vx-card__collapsible-content {
      max-height: 485px;
    }
  }

  .chat-card-log {
    height: 360px;

    .chat-sent-msg {
      background-color: #f2f4f7 !important;
    }
  }

  .card-video {
    .video-js {
      height: 370px;
    }
  }
}
.language-markup span {
  //display: block !important;
  overflow-wrap: break-word;
}

.chatbot-status {
  position: relative;
}
.chatbot-status .con-vs-chip {
  position: absolute;
  bottom: 4px;
  right: 6px;
}
.vs-sidebar--items {
  display: table !important;
}
.con-vs-dialog {
  z-index: 2147483647 !important;
}
#algolia-instant-search-demo {
  .algolia-header {
    .algolia-filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #algolia-content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .algolia-search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .algolia-price-slider {
    min-width: unset;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 100px;
  }
}

.theme-dark {
  #algolia-instant-search-demo {
    #algolia-content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }
    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #algolia-content-container {
    .vs-sidebar {
      position: absolute !important;
      float: none !important;
    }
  }
}

.qrcode {
  text-align: center;
}

.whatsapp-api-button {
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 13px;
  padding: 2px 4.5px;
  background: #25d365 !important;
  color: #fff;
  text-decoration: none;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
}
</style>
